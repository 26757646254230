import { TAction, TDispatch } from "../../../types";
import {
  RCSAEntityAction,
  RCSAEntityActionType,
  RCSAEntityModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const RCSAEntityDefault: RCSAEntityModel = {
  main: {
    data: [],
    total: 0,
  },
  rcsaDetail: {
    data: {} as any,
  },
  dependency: {
    data: {} as any,
    total: 0,
  },
  pic: {
    data: [],
    total: 0,
  },
  unit: {
    data: [],
    total: 0,
  },
  incident: {
    data: {} as any,
  },
  riskIndicator: {
    data: [],
    total: 0,
  },
};

export const RCSAEntityReducer = (
  state: RCSAEntityModel = RCSAEntityDefault,
  action: RCSAEntityAction
): RCSAEntityModel => {
  switch (action.type) {
    case RCSAEntityActionType.GetAllRCSAEntity:
      return { ...state, main: action.data };
    case RCSAEntityActionType.GetDependencyEntity:
      return { ...state, dependency: action.data };
    case RCSAEntityActionType.GetPICEntity:
      return { ...state, pic: action.data };
    case RCSAEntityActionType.GetDetailRCSAEntity:
      return { ...state, rcsaDetail: action.data };
    case RCSAEntityActionType.GetUnitRCSAEntity:
      return { ...state, unit: action.data };
    case RCSAEntityActionType.GetIncidentRCSAEntity:
      return { ...state, incident: action.data };
    case RCSAEntityActionType.GetRiskIndicator:
      return { ...state, riskIndicator: action.data };
    default:
      return { ...state };
  }
};

export const RCSAEntityCommand = {
  GetAllRCSAEntityLib: (params: any): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risk-library', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: RCSAEntityActionType.GetAllRCSAEntity,
        });
      });
    };
  },
  GetAllRCSAEntity: (params?: unknown): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risks', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: RCSAEntityActionType.GetAllRCSAEntity,
        });
      });
    };
  },
  GetDetailRCSAEntity: (params?: unknown): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risk', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: RCSAEntityActionType.GetDetailRCSAEntity,
        });
      });
    };
  },
  GetDependencyEntity: (params?: unknown): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risk/dependency', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: 1,
          },
          type: RCSAEntityActionType.GetDependencyEntity,
        });
      });
    };
  },
  GetPICEntity: (params?: any): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risk/pics', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: RCSAEntityActionType.GetPICEntity,
        });
      });
    };
  },
  GetUnitRCSAEntity: (params?: unknown): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risk/units', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: RCSAEntityActionType.GetUnitRCSAEntity,
        });
      });
    };
  },
  GetIncidentRCSAEntity: (params?: unknown): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risk/incident-history', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: RCSAEntityActionType.GetIncidentRCSAEntity,
        });
      });
    };
  },
  GetRiskIndicator: (params?: unknown): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risk/indicators', { params: params }).then((response) => {
        console.log('[][][] response', response);
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: RCSAEntityActionType.GetRiskIndicator,
        });
      });
    };
  },
  GetUnitRCSALibEntity: (params?: unknown): TAction<RCSAEntityAction, void> => {
    return (dispatch: TDispatch<RCSAEntityAction>) => {
      return fetchAPI().get('/risk-library/units', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: RCSAEntityActionType.GetUnitRCSAEntity,
        });
      });
    };
  },
  SubmitRCSAEntity: (body?: any): TAction<RCSAEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<RCSAEntityAction>) => {
      try {
        const response = await fetchAPI().post('/risk/submit', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  ApprovalRCSAEntity: (body?: any): TAction<RCSAEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<RCSAEntityAction>) => {
      try {
        const url = body.type === 'approve' ? '/risk/submit' : '/risk/reject';
        const response = await fetchAPI().post(url, body.body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  CreateRCSAEntity: (body?: any): TAction<RCSAEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<RCSAEntityAction>) => {
      try {
        const response = await fetchAPI().post('/risk', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateRCSAEntity: (body: any): TAction<RCSAEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<RCSAEntityAction>) => {
      try {
        const response = await fetchAPI().patch('/risk', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteRCSAEntity: (body: any): TAction<RCSAEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<RCSAEntityAction>) => {
      try {
        const response = await fetchAPI().delete('/risk', { data: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
