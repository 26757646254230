/**
 * download handler
 * @param response
 * @param fileName
 */
const downloadHandler = (blob: Blob, fileName: string) => {
  const href = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export { downloadHandler };
