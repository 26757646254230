import { AxiosResponse } from "axios";
import { fetchAPI } from "../../../../config/axios";
import { downloadHandler } from "../../../../utils/download";
import { TAction, TDispatch } from "../../../types";
import {
  MonitoringRiskMitigationKRIAction,
  MonitoringRiskMitigationKRIActionType,
  MonitoringRiskMitigationKRIDependencyDataModel,
  MonitoringRiskMitigationKRIDetailDataModel,
  MonitoringRiskMitigationKRIModel,
} from "./types";

export const MonitoringRiskMitigationKRIDefault: MonitoringRiskMitigationKRIModel =
  {
    main: {
      data: [] as any,
      total: 0,
    },
    detail: {
      data: {} as MonitoringRiskMitigationKRIDetailDataModel,
    },
    dependency: {
      data: {} as MonitoringRiskMitigationKRIDependencyDataModel,
    },
    pics: {
      data: [] as any,
    },
  };

export const MonitoringRiskMitigationKRIReducer = (
  state: MonitoringRiskMitigationKRIModel = MonitoringRiskMitigationKRIDefault,
  action: MonitoringRiskMitigationKRIAction
): MonitoringRiskMitigationKRIModel => {
  switch (action.type) {
    case MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigation:
      return { ...state, main: action.data };
    case MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationDetail:
      return { ...state, detail: action.data };
    case MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationDependency:
      return { ...state, dependency: action.data };
    case MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationPIC:
      return { ...state, pics: action.data };
    default:
      return { ...state };
  }
};

export const MonitoringRiskMitigationKRICommand = {
  GetAllMonitoringKriMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationKRIAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationKRIAction>) => {
      return fetchAPI()
        .get("/monitoring-kri-mitigations", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data.data,
              total: response.data.data.total,
            },
            type: MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigation,
          });
        });
    };
  },
  GetPICMonitoringKRIMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationKRIAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationKRIAction>) => {
      return fetchAPI()
        .get("/monitoring-kri-mitigation/pics", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationPIC,
          });
        });
    };
  },
  GetDetailMonitoringKriMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationKRIAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationKRIAction>) => {
      return fetchAPI()
        .get("/monitoring-kri-mitigation", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationDetail,
          });
        });
    };
  },
  GetDependencyMonitoringKriMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationKRIAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationKRIAction>) => {
      return fetchAPI()
        .get("/monitoring-kri-mitigation/dependency", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationDependency,
          });
        });
    };
  },
  UpdateMonitoringKriMitigation: (
    body: any
  ): TAction<MonitoringRiskMitigationKRIAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<MonitoringRiskMitigationKRIAction>) => {
      try {
        const response = await fetchAPI().post(
          "/monitoring-kri-mitigation",
          body
        );
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  ApprovalMonitoringKriMitigation: (
    body?: any
  ): TAction<MonitoringRiskMitigationKRIAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<MonitoringRiskMitigationKRIAction>) => {
      try {
        const url =
          body.type === "approve"
            ? "/monitoring-kri-mitigation/submit"
            : "/monitoring-kri-mitigation/reject";
        const response = await fetchAPI().post(url, body.body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DownloadEvidence: (
    params?: any
  ): TAction<MonitoringRiskMitigationKRIAction, any> => {
    return async (dispatch: TDispatch<MonitoringRiskMitigationKRIAction>) => {
      try {
        const response = await fetchAPI().get(
          `/monitoring-kri-mitigation/download`,
          {
            params,
            responseType: "blob", // Set responseType to 'blob' to handle binary data
          }
        );

        downloadHandler(response.data, params.filename);

        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
