import { TAction, TDispatch } from "../../../types";
import {
  ReportKRIAction,
  ReportKRIActionType,
  ReportKRIModel,
  ReportKRIFilterDataModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ReportKRIDefault: ReportKRIModel = {
  main: {
    data: [] as any,
    total: 0,
  },
  filter: {
    data: {} as ReportKRIFilterDataModel,
  },
};

export const ReportKRIReducer = (
  state: ReportKRIModel = ReportKRIDefault,
  action: ReportKRIAction
): ReportKRIModel => {
  switch (action.type) {
    case ReportKRIActionType.GetKRIFilter:
      return { ...state, filter: action.data };
    case ReportKRIActionType.GetKRI:
      return { ...state, main: action.data };
    default:
      return { ...state };
  }
};

export const ReportKRICommand = {
  GetKRIFilter: (body: unknown): TAction<ReportKRIAction, void> => {
    return (dispatch: TDispatch<ReportKRIAction>) => {
      return fetchAPI()
        .post("/report/kri/filters", { filter: body })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: ReportKRIActionType.GetKRIFilter,
          });
        });
    };
  },
  GetKRI: (params: unknown, body: unknown): TAction<ReportKRIAction, void> => {
    return (dispatch: TDispatch<ReportKRIAction>) => {
      return fetchAPI()
        .post("/report/kries", { filter: body }, { params })
        .then((response) => {
          const data = response.data.data;
          dispatch({
            data: {
              data: data.data,
              total: data.total,
            },
            type: ReportKRIActionType.GetKRI,
          });
        });
    };
  },
};
