export interface DashboardChartDataModel {
  options: {
    chart: {
      type: string;
      height: string;
      width: string;
      toolbar: {
        show: boolean;
      };
      zoom: {
        enabled: boolean;
      };
      fontFamily: string | null;
      foreColor: string;
      sparkline: {
        enabled: boolean;
      };
      stacked: boolean;
    };
    plotOptions: {
      bar: {
        horizontal: boolean;
      };
    };
    colors: string[];
    series: any[];
    dataLabels: {
      enabled: boolean;
    };
    theme: {
      mode: string;
    };
    title: {
      text: string;
    };
    subtitle: {
      text: string;
      align: string;
    };
    xaxis: {
      categories: any;
    };
    grid: {
      show: boolean;
    };
    markers: {
      show: boolean;
    };
    legend: {
      show: boolean;
    };
    labels: string[];
  };
  data: Array<{
    loss: number;
    label: string;
    series: number;
  }>;
  view_url: string;
}

export type TChartData = {
  id: string;
  label: string;
  value: number;
  color: string;
  loss?: number;
};

export interface DashboardCountChartModel {
  title: string;
  total: number;
}

export interface DashboardKRITrendDataModel {
  title: string;
  trend: KRITrendModel[];
  status: string;
}

export interface DashboardLEDTopLEDDataModel {
  name: string;
  frequency: string;
  gross_amount: number;
  recovery_amount: number;
  net_loss_amount: number;
  id: any;
  ulid: any;
  code: any;
  project_assignment_id: any;
  entity_id: any;
  position_id: any;
  user_id: any;
  description: any;
  start_date: Date;
  end_date: Date;
  sla_report: number;
  duration: number;
  location: string;
  taxonomy_risk_category_id: any;
  taxonomy_sub_risk_category_id: any;
  taxonomy_sub_detail_risk_category_id: any;
  taxonomy_risk_activity_id: any;
  taxonomy_risk_description_id: any;
  taxonomy_risk_description_text: any;
  taxonomy_impact_type_id: number;
  criteria_inherent_impact: any;
  criteria_inherent_impact_level: any;
  criteria_inherent_impact_color: any;
  impact_operational: string;
  impact_reputation: string;
  impact_compilance: string;
  impact_business_effeciency: string;
  impact_systems: string;
  impact_other: string;
  taxonomy_financial_impact_id: any;
  impact_financial: string;
  currency_id: number;
  coa_number: string;
  coa_name: string;
  general_ledger_date: any;
  journal_information: string;
  incident_code: string;
  status_id: any;
  status: any;
  properties: any;
  created_at: any;
  updated_at: any;
  deleted_at: any;
  parent_id: any;
  level: any;
  type: any;
  group_id: any;
  sort_order: any;
  loss_event_id: any;
  loss_event_cause_id: number;
  taxonomy_mitigation_type_id: any;
  taxonomy_mitigation_action_id: number;
  mitigation_description: string;
  due_date: Date;
  taxonomy_mitigation_status_id: number;
  timeline_status: string;
  taxonomy_control_effectiveness_id: any;
  frequency_id: number;
  evidence: string;
  notes: string;
  implementation_date: Date;
  taxonomy_controlling_status_id: any;
  controller_evidence: any;
  controller_notes: any;
  controller_date: any;
  estimated_cost: number;
  loss_event_mitigation_id: number;
  guid: string;
  domain: string;
  email: string;
  username: string;
  email_verified_at: any;
  password: string;
  remember_token: any;
  risk_id: any;
  period_id: any;
  related_process: any;
  inherent_impact_description: any;
  criteria_inherent_likelihood: any;
  criteria_inherent_likelihood_level: any;
  criteria_inherent_likelihood_color: any;
  inherent_likelihood_description: any;
  inherent_color: any;
  inherent_level: any;
  inherent_tolerance: any;
  inherent_top_risk: any;
  criteria_residual_impact: any;
  criteria_residual_impact_level: any;
  criteria_residual_impact_color: any;
  residual_impact_description: any;
  criteria_residual_likelihood: any;
  criteria_residual_likelihood_level: any;
  criteria_residual_likelihood_color: any;
  residual_likelihood_description: any;
  residual_color: any;
  residual_level: any;
  residual_tolerance: any;
  residual_top_risk: any;
  count_incident: any;
  sum_loss_amount: any;
  reassessment: any;
}

export type DashboardRiskTop = {
  code: string;
  status: string;
  project_assignment_id: string;
  risk_score: number;
  description: string;
  color: string;
  level: string;
  risk_officer: string[];
  latest_activity: any;
  priority: number;
};

export interface KRITrendModel {
  label: string;
  color: string;
}

export interface DashboardFilterDataModel {
  groups: DashboardFilterModel[];
  projects: DashboardFilterModel[];
  entities: DashboardFilterModel[];
  positions: DashboardFilterModel[];
  risk_types: DashboardFilterModel[];
  risk_category_parents: DashboardFilterModel[];
  risk_category_childs: DashboardFilterModel[];
}

export interface DashboardFilterModel {
  key: string;
  value: string;
}

export interface DashboardModel {
  main: {
    data: DashboardKRITrendDataModel[];
  };
  kriTrend: {
    data: DashboardKRITrendDataModel[];
  };
  /* Chart RCSA */
  rcsaProgress: {
    data: DashboardChartDataModel;
  };
  rcsaTotalOfficer: {
    data: DashboardCountChartModel;
  };
  rcsaTotalDivision: {
    data: DashboardCountChartModel;
  };
  rcsaTotalRisk: {
    data: DashboardCountChartModel;
  };
  riskTop: {
    data: DashboardRiskTop[];
  };
  /* Global Chart */
  riskByType: {
    data: DashboardChartDataModel;
  };
  riskProgressWorkUnit: {
    data: DashboardChartDataModel;
  };
  riskByCategory: {
    data: DashboardChartDataModel;
  };
  riskMonitoringStatus: {
    data: DashboardChartDataModel;
  };
  riskMonitoringMitigationStatus: {
    data: DashboardChartDataModel;
  };
  /* Chart KRI */
  kriTrendList: {
    data: DashboardKRITrendDataModel[];
  };
  kriProgress: {
    data: DashboardChartDataModel;
  };
  kriReportResult: {
    data: DashboardChartDataModel;
  };
  kriMonitoringStatus: {
    data: DashboardChartDataModel;
  };
  kriMonitoringMitigationStatus: {
    data: DashboardChartDataModel;
  };
  /* Chart LED */
  ledTotalIncident: {
    data: DashboardCountChartModel;
  };
  ledTotalActualLosses: {
    data: DashboardCountChartModel;
  };
  ledProgressWorkUnit: {
    data: DashboardChartDataModel;
  };
  ledProgress: {
    data: DashboardChartDataModel;
  };
  ledTotalLossesPerUnit: {
    data: DashboardChartDataModel;
  };
  ledTop: {
    data: DashboardLEDTopLEDDataModel[];
  };
  ledMonitoringStatus: {
    data: DashboardChartDataModel;
  };
  ledMonitoringMitigationStatus: {
    data: DashboardChartDataModel;
  };
  /* Filter Chart */
  filterDashboard: {
    data: DashboardFilterDataModel;
  };
}

export enum DashboardActionType {
  /* RCSA Chart */
  GetKRITrend = "⌘➝Dashboard➝GetKRITrend",
  GetDashboardChart = "⌘➝Dashboard➝GetDashboardChart",
  GetRiskProgress = "⌘➝Dashboard➝GetRiskProgress",
  GetRiskByType = "⌘➝Dashboard➝GetRiskByType",
  GetRiskTop = "⌘➝Dashboard➝GetRiskTop",
  GetRiskProgressWorkUnit = "⌘➝Dashboard➝GetRiskProgressWorkUnit",
  GetRiskByCategory = "⌘➝Dashboard➝GetRiskByCategory",
  GetRiskMonitoringStatus = "⌘➝Dashboard➝GetRiskMonitoringStatus",
  GetRiskMonitoringMitigationStatus = "⌘➝Dashboard➝GetRiskMonitoringMitigationStatus",
  /* Count Chart */
  GetRiskTotalOfficer = "⌘➝Dashboard➝GetRiskTotalOfficer",
  GetRiskTotalDivision = "⌘➝Dashboard➝GetRiskTotalDivision",
  GetRiskTotalRisk = "⌘➝Dashboard➝GetRiskTotalRisk",
  /* KRI Chart */
  GetKRIProgress = "⌘➝Dashboard➝GetKRIProgress",
  GetKRIReportResult = "⌘➝Dashboard➝GetKRIReportResult",
  GetKRIMonitoringStatus = "⌘➝Dashboard➝GetKRIMonitoringStatus",
  GetKRIMonitoringMitigationStatus = "⌘➝Dashboard➝GetKRIMonitoringMitigationStatus",
  GetKRITrendList = "⌘➝Dashboard➝GetKRITrendList",
  /* LED Chart */
  GetLEDTotalIncident = "⌘➝Dashboard➝GetLEDTotalIncident",
  GetLEDTotalActualLosses = "⌘➝Dashboard➝GetLEDTotalActualLosses",
  GetLEDProgressWorkUnit = "⌘➝Dashboard➝GetLEDProgressWorkUnit",
  GetLEDProgress = "⌘➝Dashboard➝GetLEDProgress",
  GetLEDTotalLossesPerUnit = "⌘➝Dashboard➝GetLEDTotalLossesPerUnit",
  GetTopLED = "⌘➝Dashboard➝GetTopLED",
  GetLEDMonitoringStatus = "⌘➝Dashboard➝GetLEDMonitoringStatus",
  GetLEDMonitoringMitigationStatus = "⌘➝Dashboard➝GetLEDMonitoringMitigationStatus",

  /* Filter Chart */
  GetFilterDashboard = "⌘➝Dashboard➝GetFilterDashboard",
}

export type DashboardAction =
  | {
      data: {
        data: DashboardKRITrendDataModel[];
      };
      type: DashboardActionType.GetDashboardChart;
    }
  | {
      data: {
        data: DashboardKRITrendDataModel[];
      };
      type: DashboardActionType.GetKRITrend;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetRiskProgress;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetRiskByType;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetRiskProgressWorkUnit;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetRiskByCategory;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetRiskMonitoringStatus;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetRiskMonitoringMitigationStatus;
    }
  | {
      data: {
        data: DashboardCountChartModel;
      };
      type: DashboardActionType.GetRiskTotalOfficer;
    }
  | {
      data: {
        data: DashboardCountChartModel;
      };
      type: DashboardActionType.GetRiskTotalDivision;
    }
  | {
      data: {
        data: DashboardCountChartModel;
      };
      type: DashboardActionType.GetRiskTotalRisk;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetKRIProgress;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetKRIMonitoringStatus;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetKRIMonitoringMitigationStatus;
    }
  | {
      data: {
        data: DashboardKRITrendDataModel[];
      };
      type: DashboardActionType.GetKRITrendList;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetKRIReportResult;
    }
  | {
      data: {
        data: DashboardCountChartModel;
      };
      type: DashboardActionType.GetLEDTotalIncident;
    }
  | {
      data: {
        data: DashboardCountChartModel;
      };
      type: DashboardActionType.GetLEDTotalActualLosses;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetLEDProgressWorkUnit;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetLEDProgress;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetLEDTotalLossesPerUnit;
    }
  | {
      data: {
        data: DashboardLEDTopLEDDataModel[];
      };
      type: DashboardActionType.GetTopLED;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetLEDMonitoringStatus;
    }
  | {
      data: {
        data: DashboardChartDataModel;
      };
      type: DashboardActionType.GetLEDMonitoringMitigationStatus;
    }
  | {
      data: {
        data: DashboardFilterDataModel;
      };
      type: DashboardActionType.GetFilterDashboard;
    }
  | {
      data: {
        data: DashboardRiskTop[];
      };
      type: DashboardActionType.GetRiskTop;
    };
