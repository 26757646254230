import { LatestActivityDataModel } from "../../shared/types";
import {
  MonitoringEvidenceDataModel,
  MonitoringPIC,
  MonitoringPICDataModel,
} from "../shared/types";

export interface MonitoringRiskMitigationRCSADataModel {
  ulid: string;
  code: string;
  mitigation_description: string;
  status: string;
  stage: string;
  risk_code: string;
  risk_description: string;
  risk_cause: string;
  impact_description: string;
  inherent_level: string;
}

export interface MonitoringRiskMitigationRCSADetailDataModel {
  ulid: string;
  code: string;
  taxonomy_mitigation_type_id: string;
  taxonomy_mitigation_action_id: string;
  mitigation_description: string;
  due_date: string;
  taxonomy_mitigation_status_id: string | null;
  timeline_status: string | null;
  taxonomy_control_effectiveness_id: string | null;
  frequency_id: string | null;
  evidences: MonitoringEvidenceDataModel[];
  notes: string | null;
  implementation_date: string | null;
  taxonomy_controlling_status_id: string | null;
  controller_evidences: MonitoringEvidenceDataModel[];
  controller_notes: string | null;
  controller_date: string | null;
  status: string;
  updated_at: string;
  risk: {
    description: string;
    taxonomy_risk_activity_id: string;
    taxonomy_risk_description_id: string;
    taxonomy_risk_description_text: string;
    taxonomy_risk_category_id: string;
    taxonomy_sub_risk_category_id: string;
    taxonomy_sub_detail_risk_category_id: string;
  };
  stage: string | null;
  latest_activity: LatestActivityDataModel[];
  risk_cause: {
    description: string;
  };
  risk_mitigation_pic: MonitoringPIC[];
  risk_controlling_pic: {
    position_id: string;
    user_id: string;
  }[];
}

type TSub = {
  id: string;
  name: string;
};
type TSubWithParent = TSub & {
  parent_id: string;
};

type TTaxonomy = {
  risk_category: TSub[];
  sub_risk_category: TSubWithParent[];
  sub_detail_risk_category: TSubWithParent[];
  mitigation_type: TSub[];
  mitigation_status: TSub[];
  controlling_status: TSub[];
  mitigation_action: TSub[];
  mitigation_effectiveness: TSub[];
};

export interface MonitoringRiskMitigationRCSADependencyDataModel {
  division_name: string;
  directorate_name: string;
  project_assignment_id: string;
  taxonomy: TTaxonomy;
  frequency: TSub[];
}

export interface MonitoringRiskMitigationRCSAModel {
  main: {
    data: MonitoringRiskMitigationRCSADataModel[];
    total: number;
  };
  detail: {
    data: MonitoringRiskMitigationRCSADetailDataModel;
  };
  dependency: {
    data: MonitoringRiskMitigationRCSADependencyDataModel;
  };
  pics: {
    data: MonitoringPICDataModel[];
  };
}

export enum MonitoringRiskMitigationRCSAActionType {
  GetMonitoringRiskMitigationRCSA = "⌘➝Monitoring➝RiskMitigationRCSA➝GetMonitoringRiskMitigation",
  GetMonitoringRiskMitigationRCSADetail = "⌘➝Monitoring➝RiskMitigationRCSA➝GetMonitoringRiskMitigationDetail",
  GetMonitoringRiskMitigationRCSADependency = "⌘➝Monitoring➝RiskMitigationRCSA➝GetMonitoringRiskMitigationDependency",
  GetMonitoringRiskMitigationRCSAPIC = "⌘➝Monitoring➝RiskMitigationRCSA➝GetMonitoringRiskMitigationPIC",
}

export type MonitoringRiskMitigationRCSAAction =
  | {
      data: {
        data: MonitoringRiskMitigationRCSADataModel[];
        total: number;
      };
      type: MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSA;
    }
  | {
      data: {
        data: MonitoringRiskMitigationRCSADetailDataModel;
      };
      type: MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSADetail;
    }
  | {
      data: {
        data: MonitoringPICDataModel[];
      };
      type: MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSAPIC;
    }
  | {
      data: {
        data: MonitoringRiskMitigationRCSADependencyDataModel;
      };
      type: MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSADependency;
    };
