import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import { fetchAPI } from "./config/axios";
import { notify } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { ConfigureUserCommand } from "./redux/app/configure/user/reducer";

interface Abilities {}

interface User {
  ulid: string;
  name: string;
  email: string;
  status: string;
}

interface UserInformation {
  token: string;
  abilities: any;
  user: User;
  roles: any;
}

interface FormData {
  username: string;
  email: string;
  password: string;
  type: string;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  userinfo: UserInformation;
  login: (formData: any) => void;
  loginAsDelegation: (toUserId: string) => void;
  logout: () => void;
  exitDelegation: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userinfo, setUserInfo] = useState<UserInformation>({
    token: "",
    abilities: [],
    user: {
      ulid: "",
      name: "",
      email: "",
      status: "",
    },
    roles: [],
  });

  useEffect(() => {
    const parsedUserData = localStorage.getItem("userinfo");
    if (parsedUserData) {
      let userlogin = parsedUserData ? JSON.parse(parsedUserData) : {};
      setUserInfo({
        token: userlogin.token,
        abilities: userlogin.abilities,
        user: {
          ulid: userlogin.user.ulid,
          name: userlogin.user.name,
          email: userlogin.user.email,
          status: userlogin.user.status,
        },
        roles: userlogin.roles,
      });
      dispatch(ConfigureUserCommand.LoadUserFromLocalStorage() as any);
    } else {
      // logout();
    }
  }, []);

  const login = async (formData) => {
    return await handleLogin(formData);
  };

  const handleLogin = async (formData) => {
    try {
      const requestBody =
        formData.type === "email"
          ? { email: formData.email, password: formData.password }
          : ({
              username: formData.username,
              password: formData.password,
            } as any);
      requestBody.device_name = "desktop";

      const responses = await dispatch(
        ConfigureUserCommand.UserLogin({ ...requestBody }) as any
      );
      const response = responses.data.data;
      localStorage.setItem("userinfo", JSON.stringify(response));
      /* Set User Login Credentials */
      setUserInfo({
        token: response.token,
        abilities: response.abilities,
        user: {
          ulid: response.user.ulid,
          name: response.user.name,
          email: response.user.email,
          status: response.user.status,
        },
        roles: response.roles,
      });

      notify.success({
        title: "Success",
        description: "Login Verified",
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 200);

      return { success: true, data: response };
    } catch (error: any) {
      // Handle login errors
      if (error.response && error.response.status === 422) {
        const validationError = error.response.data;
        for (let key in validationError.data) {
          notify.error({
            title: validationError.error,
            description: validationError.data[key][0],
          });
        }
      } else {
        notify.error({
          title: "Error",
          description: error.response.data.error,
        });
      }
      return {
        success: false,
        error: error,
      };
    }
  };

  const exitDelegation = useCallback(() => {
    const userDelegated = localStorage.getItem("userinfo-delegated");
    if (userDelegated) {
      localStorage.setItem("userinfo", userDelegated);
      localStorage.removeItem("userinfo-delegated");

      notify.success({
        title: "Success",
        description: "Exit as Delegation",
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 200);
    }
  }, []);

  const loginAsDelegation = useCallback(
    async (toUserId: string) => {
      try {
        const res = await dispatch(
          ConfigureUserCommand.LoginAsDelegation({
            to_user_id: toUserId,
          }) as any
        );
        const response = res.data.data.data;

        const currentUser = localStorage.getItem("userinfo");
        if (currentUser) {
          localStorage.setItem("userinfo-delegated", currentUser);
        }

        localStorage.setItem("userinfo", JSON.stringify(response));
        /* Set User Login Credentials */
        setUserInfo({
          token: response.token,
          abilities: response.abilities,
          user: {
            ulid: response.user.ulid,
            name: response.user.name,
            email: response.user.email,
            status: response.user.status,
          },
          roles: response.roles,
        });

        notify.success({
          title: "Success",
          description: "Login Verified",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 200);

        return { success: true, data: response };
      } catch (error: any) {
        notify.error({
          title: "Error",
          description: error.response.data.error,
        });

        return {
          success: false,
          error: error,
        };
      }
    },
    [dispatch]
  );

  const logout = async () => {
    try {
      const response = await fetchAPI()
        .post("/user/logout")
        .then((response) => {
          notify.success({
            title: "Success",
            description: "Success Logout",
          });
          localStorage.removeItem("userinfo-delegated");
          localStorage.removeItem("userinfo");
          setTimeout(() => {
            window.location.href = "/login";
          }, 200);
        })
        .catch((error) => {
          notify.error({
            title: "Error",
            description: error.message,
          });
        });
    } catch (error) {
      // Handle logout errors
      console.error("[][][] Login error:", error);
    }
  };

  const authContextValue: AuthContextProps = {
    isAuthenticated,
    userinfo,
    login,
    logout,
    exitDelegation,
    loginAsDelegation,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
