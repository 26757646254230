import { LatestActivityDataModel } from "../../shared/types";
import {
  MonitoringEvidenceDataModel,
  MonitoringPICDataModel,
} from "../shared/types";

export type MonitoringRiskMitigationKRIDataModel = {
  ulid: string;
  code: null;
  description: string;
  status: string;
  kri_risk_description: string;
  kri_monitoring_code: string;
  kri_treshold: Treshold;
  mitigation_status: string | null;
  stage: string | null;
};

export type MonitoringRiskMitigationKRIDetailDataModel = {
  ulid: string;
  code: string | null;
  taxonomy_mitigation_action_id: string;
  taxonomy_controlling_status_id: string | null;
  description: string;
  due_date: string;
  taxonomy_mitigation_status_id: string | null;
  timeline_status: string | null;
  taxonomy_control_effectiveness_id: string | null;
  frequency_id: string | null;
  evidences: MonitoringEvidenceDataModel[] | null;
  controller_evidences: MonitoringEvidenceDataModel[] | null;
  notes: string | null;
  controller_notes: string | null;
  controller_date: string | null;
  implementation_date: string | null;
  status: string;
  stage: string | null;
  latest_activity: LatestActivityDataModel[];
  kri_monitoring: KriMonitoring;
  kri_monitoring_mitigation_pic: KriMonitoringMitigationPic[];
  kri_monitoring_controlling_pic: KriMonitoringMitigationPic[];
};

type TSub = {
  id: string;
  name: string;
};
type TSubWithParent = TSub & {
  parent_id: string;
};

type TTaxonomy = {
  risk_category: TSub[];
  sub_risk_category: TSubWithParent[];
  sub_detail_risk_category: TSubWithParent[];
  mitigation_type: TSub[];
  mitigation_status: TSub[];
  controlling_status: TSub[];
  mitigation_action: TSub[];
  mitigation_effectiveness: TSub[];
};

export type MonitoringRiskMitigationKRIDependencyDataModel = {
  division_name: string;
  directorate_name: string;
  project_assignment_id: string;
  taxonomy: TTaxonomy;
  frequency: TSub[];
};

export type KriMonitoring = {
  ulid: string;
  code: string;
  kri_risk_description_id: string;
  kri_treshold_id: string;
  project_assignment_id: string;
  entity_id: string;
  position_id: string;
  user_id: string;
  value: number;
  description: string;
  remarks: null;
  count_incident: number;
  sum_loss_amount: number;
  status: string;
  created_at: Date;
  latest_activity: LatestActivity[];
  treshold: Treshold;
  kri_history: KriHistory[];
  risk_description: string;
  kri_monitoring_related_risks_notes: string;
  priority: number;
};

export type KriHistory = {
  kri_monitoring_id: string;
  value: number;
  date: Date;
  name: string;
  color: string;
  unit: string;
  monitoring: string;
};

export type LatestActivity = {
  notes: string;
  action: string;
  status: string;
  modify_by: string;
  modify_date: Date;
};

export type Treshold = {
  name: string;
  color: string;
  unit: string;
  flag_monitoring: boolean;
  monitoring: string;
};

export type KriMonitoringMitigationPic = {
  ulid: string;
  position_id: string;
  user_id: string;
};

export interface MonitoringRiskMitigationKRIModel {
  main: {
    data: MonitoringRiskMitigationKRIDataModel[];
    total: number;
  };
  detail: {
    data: MonitoringRiskMitigationKRIDetailDataModel;
  };
  dependency: {
    data: MonitoringRiskMitigationKRIDependencyDataModel;
  };
  pics: {
    data: MonitoringPICDataModel[];
  };
}

export enum MonitoringRiskMitigationKRIActionType {
  GetMonitoringKRIMitigation = "⌘➝Monitoring➝KRIMitigation➝GetMonitoringKRIMitigation",
  GetMonitoringKRIMitigationDetail = "⌘➝Monitoring➝KRIMitigation➝GetMonitoringKRIMitigationDetail",
  GetMonitoringKRIMitigationDependency = "⌘➝Monitoring➝KRIMitigationKRI➝GetMonitoringKRIMitigationDependency",
  GetMonitoringKRIMitigationPIC = "⌘➝Monitoring➝KRIMitigationKRI➝GetMonitoringKRIMitigationPIC",
}

export type MonitoringRiskMitigationKRIAction =
  | {
      data: {
        data: MonitoringRiskMitigationKRIDataModel[];
        total: number;
      };
      type: MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigation;
    }
  | {
      data: {
        data: MonitoringRiskMitigationKRIDetailDataModel;
      };
      type: MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationDetail;
    }
  | {
      data: {
        data: MonitoringPICDataModel[];
      };
      type: MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationPIC;
    }
  | {
      data: {
        data: MonitoringRiskMitigationKRIDependencyDataModel;
      };
      type: MonitoringRiskMitigationKRIActionType.GetMonitoringKRIMitigationDependency;
    };
