export interface LEDLossEventMainDataModel {
  ulid?: any;
  code?: string;
  status?: string;
  division: string;
  risk_category: string;
  event_start_datetime: string;
  event_description: string;
  event_causes?: string[];
  mitigation_descriptions?: string[];
  mitigation_status?: string[];
  monitoring_status?: string[];
  priority: number;
  id?: any;
  entity_id?: any;
  risk_code?: any;
  user_id?: any;
  position_id?: any;
  project_assignment_id?: any;
  taxonomy_sub_detail_risk_category_id?: any;
  created_at?: any;
  loss_event_causes?: any;
  name?: any;
  description?: any;
  start_date?: any;
  end_date?: any;
  duration?: any;
  location?: any;
  taxonomy_risk_category_id?: any;
  taxonomy_sub_risk_category_id?: any;
  taxonomy_risk_activity_id?: any;
  taxonomy_risk_description_id?: any;
  taxonomy_risk_description_text?: any;
  taxonomy_impact_type_id?: any;
  taxonomy_event_type_id?: any;
  frequency?: any;
  criteria_inherent_impact?: any;
  criteria_inherent_impact_color?: any;
  criteria_inherent_impact_level?: any;
  sla_report: any;
  impact_operational?: any;
  impact_reputation?: any;
  impact_compilance?: any;
  impact_business_effeciency?: any;
  impact_systems?: any;
  impact_other?: any;
  taxonomy_financial_impact_id?: any;
  impact_financial?: any;
  gross_amount?: any;
  recovery_amount?: any;
  net_loss_amount?: any;
  currency_id?: any;
  coa_number?: any;
  coa_name?: any;
  general_ledger_date?: any;
  journal_information?: any;
  incident_code?: any;
  loss_event_related_risks?: any;
  latest_activity?: any;
  taxonomy_impact_type?: any;
  taxonomy_financial_impact?: any;
  taxonomy_risk_category?: any;
  taxonomy_sub_risk_category?: any;
  taxonomy_sub_detail_risk_category?: any;
  taxonomy_risk_activity?: any;
}

export interface LEDLossEventDependencyDataModel {
  user_id: string;
  entity_id: string;
  position_id: string;
  position_name: string;
  division_name: string;
  directorate_name: string;
  project_assignment_id: string;
  project_name: string;
  assignment_information: any;
  taxonomy: any;
  currency: any;
  criteria: {
    matrix: any[];
    matrix_level: any;
  };
}

export interface LEDLossEventPICModel {
  user_id: string;
  user_name: string;
  position_id: string;
  position_name: string;
  entity_id: string;
  entity_name: string;
  role_title: string;
}

export interface LEDLossEventRCSAModel {
  ulid: string;
  code: string;
  risk_description: string;
  criteria_residual_impact: any;
  criteria_residual_impact_level: any;
  criteria_residual_impact_color: any;
  criteria_residual_likelihood: any;
  criteria_residual_likelihood_level: any;
  criteria_residual_likelihood_color: any;
  residual_color: any;
  residual_level: any;
  residual_tolerance: any;
}

export interface LEDLossEventUnitModel {
  unit_id: string;
  unit_name: string;
  detail: any;
}

export interface LEDLossEventModel {
  main: {
    data: LEDLossEventMainDataModel[];
    total: number;
  };
  ledDetail: {
    data: LEDLossEventMainDataModel;
  };
  dependency: {
    data: LEDLossEventDependencyDataModel;
    total: number;
  };
  pic: {
    data: LEDLossEventPICModel[];
    total: number;
  };
  rcsa: {
    data: LEDLossEventRCSAModel[];
    total: number;
  };
  unit: {
    data: LEDLossEventUnitModel[];
    total: number;
  };
}

export enum LEDLossEventActionType {
  GetAllLEDLossEvent = "⌘➝LED➝Loss Event➝GetAllLEDLossEvent",
  GetLEDLossEventDependency = "⌘➝LED➝Loss Event➝GetLEDLossEventDependency",
  GetPICLedLossEvent = "⌘➝LED➝Loss Event➝GetPICLedLossEvent",
  GetDetailLEDLossEvent = "⌘➝LED➝Loss Event➝GetDetailLEDLossEvent",
  GetRCSALEDLossEvent = "⌘➝LED➝Loss Event➝GetRCSALEDLossEvent",
  GetUnitLEDLossEvent = "⌘➝LED➝Loss Event➝GetUnitLEDLossEvent",
}

export type LEDLossEventAction =
  | {
      data: {
        data: LEDLossEventMainDataModel[];
        total: number;
      };
      type: LEDLossEventActionType.GetAllLEDLossEvent;
    }
  | {
      data: {
        data: LEDLossEventDependencyDataModel;
        total: number;
      };
      type: LEDLossEventActionType.GetLEDLossEventDependency;
    }
  | {
      data: {
        data: LEDLossEventPICModel[];
        total: number;
      };
      type: LEDLossEventActionType.GetPICLedLossEvent;
    }
  | {
      data: {
        data: LEDLossEventMainDataModel;
      };
      type: LEDLossEventActionType.GetDetailLEDLossEvent;
    }
  | {
      data: {
        data: LEDLossEventRCSAModel[];
        total: number;
      };
      type: LEDLossEventActionType.GetRCSALEDLossEvent;
    }
  | {
      data: {
        data: LEDLossEventUnitModel[];
        total: number;
      };
      type: LEDLossEventActionType.GetUnitLEDLossEvent;
    };
