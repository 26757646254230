export type ReportKRIDataModel = {
  ulid: string;
  code: string;
  value: number;
  description: string;
  remarks: string;
  count_incident: number;
  sum_loss_amount: number;
  created_at: Date;
  taxonomy_status: TaxonomyStatus;
  kri_treshold: KriTreshold;
  project_assignment: ProjectAssignment;
  entity: Entity;
  kri_risk_description: KriRiskDescription;
  mitigations: Mitigation[];
  related_risks: any[];
};

export type Entity = {
  ulid: string;
  name: string;
  parent: TaxonomyStatus;
};

export type TaxonomyStatus = {
  ulid: string;
  name: string;
};

export type KriRiskDescription = {
  ulid: string;
  code: string;
  source: string;
  type: string;
  value: string;
  unit: string;
  date_start: number;
  date_end: number;
  reminder: number;
  remainded_at: null;
  frequency: number;
  sort_order: number;
  status: string;
  properties: null;
  taxonomy: TaxonomyStatus;
};

export type KriTreshold = {
  ulid: string;
  code: null;
  name: string;
  color: string;
};

export type Mitigation = {
  ulid: string;
  code: null;
  description: string;
  due_date: Date;
  timeline_status: null;
  frequency_id: null;
  evidence: null;
  notes: null;
  implementation_date: null;
  properties: null;
  controller_evidence: null;
  controller_notes: null;
  controller_date: null;
  currency_id: number;
  estimated_cost: number;
  taxonomy_mitigation_action: TaxonomyStatus;
  taxonomy_submission_status: Entity | null;
  taxonomy_implementation_status: Entity | null;
  taxonomy_controlling_status: Entity | null;
  pics: Pic[];
};

export type Pic = {
  ulid: string;
  user: TaxonomyStatus;
};

export type ProjectAssignment = {
  ulid: string;
  project: Project;
};

export type Project = {
  ulid: string;
  name: string;
  group: null;
};

export interface ReportKRIFilterDataModel {
  groups: ReportKRIFilterModel[];
  projects: ReportKRIFilterModel[];
  entities: ReportKRIFilterModel[];
  risk_categories: ReportKRIFilterModel[];
  implementation_status: ReportKRIFilterModel[];
  kri_risk_description: ReportKRIFilterModel[];
  risk_levels: ReportKRIFilterModel[];
  risk_officers: ReportKRIFilterModel[];
  risk_status: ReportKRIFilterModel[];
  submission_status: ReportKRIFilterModel[];
  status: ReportKRIFilterModel[];
  positions: ReportKRIFilterModel[];
}

export interface ReportKRIFilterModel {
  key: string;
  value: string;
  val: string;
}

export interface ReportKRIModel {
  main: {
    data: ReportKRIDataModel[];
    total: number;
  };
  filter: {
    data: ReportKRIFilterDataModel;
  };
}

export enum ReportKRIActionType {
  GetKRIFilter = "⌘➝Report➝KRI➝GetKRIFilter",
  GetKRI = "⌘➝Report➝KRI➝GetKRI",
}

export type ReportKRIAction =
  | {
      data: {
        data: ReportKRIFilterDataModel;
      };
      type: ReportKRIActionType.GetKRIFilter;
    }
  | {
      data: {
        data: ReportKRIDataModel[];
        total: number;
      };
      type: ReportKRIActionType.GetKRI;
    };
