export interface ReportRiskProfileDataModel {
  code: string;
  unit: string;
  risk_category_level_1: string;
  risk_category_level_2: string;
  risk_description: string;
  risk_cause: string;
  risk_mitigation: string;
  inherent_impact: string;
  residual_likelihood: string;
  residual_impact: string;
  residual_value: string;
  risk_officer: string;
}

export interface ReportRiskProfileFilterDataModel {
  groups: ReportRiskProfileFilterModel[];
  projects: ReportRiskProfileFilterModel[];
  entities: ReportRiskProfileFilterModel[];
  risk_categories: ReportRiskProfileFilterModel[];
  risk_implementation_status: ReportRiskProfileFilterModel[];
  risk_levels: ReportRiskProfileFilterModel[];
  risk_officers: ReportRiskProfileFilterModel[];
  risk_status: ReportRiskProfileFilterModel[];
  risk_submission_status: ReportRiskProfileFilterModel[];
  positions: ReportRiskProfileFilterModel[];
}

export interface ReportRiskProfileFilterModel {
  key: string;
  value: string;
}

export interface ReportRiskProfileModel {
  main: {
    data: ReportRiskProfileDataModel[];
    total: number;
  };
  filter: {
    data: ReportRiskProfileFilterDataModel;
  };
}

export enum ReportRiskProfileActionType {
  GetRiskProfileFilter = "⌘➝Report➝RiskProfile➝GetRiskProfileFilter",
  GetRiskProfile = "⌘➝Report➝RiskProfile➝GetRiskProfile",
}

export type ReportRiskProfileAction =
  | {
      data: {
        data: ReportRiskProfileFilterDataModel;
      };
      type: ReportRiskProfileActionType.GetRiskProfileFilter;
    }
  | {
      data: {
        data: ReportRiskProfileDataModel[];
        total: number;
      };
      type: ReportRiskProfileActionType.GetRiskProfile;
    };
