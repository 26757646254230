export interface ReportLossEventDataModel {
  ulid: string;
  code: string;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  sla_report: number;
  duration: number;
  location: string;
  frequency: number;
  criteria_inherent_impact: number;
  criteria_inherent_impact_level: string;
  criteria_inherent_impact_color: string;
  impact_operational: string;
  impact_reputation: string;
  impact_compilance: string;
  impact_business_effeciency: string;
  impact_systems: string;
  impact_other: string;
  impact_financial: string;
  gross_amount: number;
  recovery_amount: number;
  net_loss_amount: number;
  coa_number: string;
  coa_name: string;
  general_ledger_date: null;
  journal_information: string;
  incident_code: string;
  created_at: string;
  updated_at: string;
  entity: TEntity;
  position: TEntity;
  user: TEntity;
  taxonomy_risk_category: TEntity;
  taxonomy_sub_risk_category: TEntity;
  taxonomy_sub_detail_risk_category: TEntity;
  taxonomy_risk_description: TEntity;
  taxonomy_impact_type: TEntity;
  taxonomy_financial_impact: TEntity | null;
  taxonomy_status: TEntity;
  causes: TCause[];
  related_risks: {
    ulid: string;
    risk: {
      ulid: string;
      taxonomy_risk_description: {
        ulid: string;
        name: string;
      };
    };
  }[];
}

export type TEntity = {
  ulid: string;
  name: string;
  parent?: {
    ulid: string;
    name: string;
  };
};

export type TCause = {
  ulid: string;
  description: string;
  status: string;
  properties: null;
  mitigations: TMitigation[];
};

export type TMitigation = {
  ulid: string;
  code: string;
  mitigation_description: string;
  due_date: Date;
  timeline_status: string;
  frequency_id: null;
  evidence: null;
  notes: null;
  implementation_date: null;
  controller_evidence: null;
  controller_notes: null;
  controller_date: null;
  estimated_cost: number;
  status: string;
  updated_at: Date;
  taxonomy_mitigation_type: TEntity | null;
  taxonomy_mitigation_action: TEntity;
  taxonomy_control_effectiveness: TEntity | null;
  taxonomy_submission_status: TEntity;
  taxonomy_implementation_status: TEntity | null;
  taxonomy_controlling_status: TEntity | null;
  pics: TPic[];
  controlling_pics: any[];
};
export type TPic = {
  position: TEntity;
  user: TEntity;
};

export interface ReportLossEventFilterDataModel {
  groups: ReportLossEventFilterModel[];
  projects: ReportLossEventFilterModel[];
  entities: ReportLossEventFilterModel[];
  risk_officers: ReportLossEventFilterModel[];
  status: ReportLossEventFilterModel[];
  submission_status: ReportLossEventFilterModel[];
  implementation_status: ReportLossEventFilterModel[];
  incident_category: ReportLossEventFilterModel[];
}

export interface ReportLossEventFilterModel {
  key: string;
  value: string;
}

export interface ReportLossEventModel {
  main: {
    data: ReportLossEventDataModel[];
    total: number;
  };
  filter: {
    data: ReportLossEventFilterDataModel;
  };
}

export enum ReportLossEventActionType {
  GetLossEventFilter = "⌘➝Report➝LossEvent➝GetLossEventFilter",
  GetLossEvent = "⌘➝Report➝LossEvent➝GetLossEvent",
}

export type ReportLossEventAction =
  | {
      data: {
        data: ReportLossEventFilterDataModel;
      };
      type: ReportLossEventActionType.GetLossEventFilter;
    }
  | {
      data: {
        data: ReportLossEventDataModel[];
        total: number;
      };
      type: ReportLossEventActionType.GetLossEvent;
    };
